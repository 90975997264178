<template>
  <div class="table-uploaded-materials">
    <div class="top">
      <div class="title">{{ material.entityName }}</div>
    </div>
    <div class="bottom">
      <div class="row" v-if="material.entityDocuments">
        <q-table
          :data="material.entityDocuments"
          :columns="columns"
          :hide-bottom="true"
          :pagination.sync="pagination"
          :sort-method="customSort"
        >
          <template v-slot:body="props">
            <q-tr
              :props="props"
              @click="$router.push(`/pdf/${props.row.documentId}`)"
            >
              <q-td key="name" :props="props" class="td-upload-name">
                <span class="pdf-icon"></span> {{ props.row.documentFileName }}
              </q-td>
              <q-td key="type" :props="props" class="td-upload-type">
                {{ props.row.sourceDocumentType }}
              </q-td>
              <q-td key="amount" :props="props" class="td-amount">
                {{ props.row.numberOfDataFound }}
              </q-td>
              <q-td key="updatedOn" :props="props" class="td-update">
                {{ props.row.updatedOn | formattingDate }}
              </q-td>
              <q-td key="actions" :props="props" class="td-actions">
                <q-btn class="btn-eye" type="button"></q-btn>
                <!-- Button disabled for now because it does not do anything -->
                <!-- <q-btn
                  class="btn-remove"
                  type="button"
                  @click="remove($event, props.row.documentId)"
                ></q-btn> -->
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>
  </div>
</template>

<script>
import { getTime } from 'date-fns'

export default {
  name: 'TableUploadedMaterials',
  props: {
    material: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      columns: [
        {
          name: 'name',
          label: 'Nom de la source',
          field: 'name',
          sortable: true,
          align: 'left'
        },
        {
          name: 'type',
          label: 'Type de source',
          field: 'type',
          sortable: true,
          align: 'left'
        },
        {
          name: 'amount',
          label: 'Nombre d\'information extraites',
          field: 'amount',
          sortable: true,
          align: 'left'
        },
        {
          name: 'updatedOn',
          label: 'Dernière màj.',
          field: 'updatedOn',
          sortable: true,
          align: 'left'
        },
        { name: 'actions', label: 'Actions', field: 'actions', align: 'left' }
      ],
      pagination: {
        rowsPerPage: this.material.entityDocuments.length
      }
    }
  },
  methods: {
    remove(e, id) {
      e.stopPropagation()
      this.$emit('remove', id)
    },
    customSort(rows, sortBy, descending) {
      const data = [...rows]

      switch (sortBy) {
        case 'name':
          return this.handlerSort(data, descending, 'documentFileName')
        case 'type':
          return this.handlerSort(data, descending, 'sourceDocumentType')
        case 'amount':
          return this.handlerSort(data, descending, 'numberOfDataFound')
        case 'updatedOn':
          return this.handlerSort(data, descending, 'updatedOn')
        default:
          return data
      }
    },
    handlerSort(data, descending, sortBy) {
      return data.sort((a, b) => {
        const x = descending ? b : a
        const y = descending ? a : b

        if (sortBy === 'updatedOn') {
          const xFormat = getTime(new Date(x[sortBy]))
          const yFormat = getTime(new Date(y[sortBy]))

          return xFormat > yFormat ? 1 : xFormat < yFormat ? -1 : 0
        }

        if (sortBy === 'numberOfDataFound') {
          return x[sortBy] > y[sortBy] ? 1 : x[sortBy] < y[sortBy] ? -1 : 0
        }

        return x[sortBy].toLowerCase() > y[sortBy].toLowerCase()
          ? 1
          : x[sortBy].toLowerCase() < y[sortBy].toLowerCase()
          ? -1
          : 0
      })
    }
  }
}
</script>
