<template>
  <div class="uploaded-materials">
    <q-page-container>
      <q-page>
        <div v-if="getMaterialsByProjectId.length">
          <TableUploadedMaterials
            v-for="(material, index) in getMaterialsByProjectId"
            :key="index"
            :material="material"
            @remove="remove"
          />
        </div>
        <LoadingOrEmpty
          v-else
          :loading="getLoadingMaterialsByProjectId"
        ></LoadingOrEmpty>
        <DeleteModal
          :content="contentDelete"
          :is-show.sync="isDelete"
          @closeModal="closeDeleteModal"
          @removed="removed"
        />
      </q-page>
    </q-page-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TableUploadedMaterials from '@/components/uploaded-materials/TableUploadedMaterials.vue'
import DeleteModal from '@/components/modals/Delete.vue'
import LoadingOrEmpty from '@/components/global/LoadingOrEmpty'

export default {
  name: 'UploadedMaterials',
  components: {
    LoadingOrEmpty,
    DeleteModal,
    TableUploadedMaterials
  },
  data() {
    return {
      isDelete: false,
      contentDelete: {
        title: 'Delete upload material',
        text: [
          'Are you sure want to delete this material?',
          'You can’t undo this action.'
        ],
        id: null,
        removeUrl: ''
      }
    }
  },
  async created() {
    const { projectId } = this.$route.params
    await this.fetchMaterialsByProjectId({ projectId })
  },
  computed: {
    ...mapGetters('documents', [
      'getMaterialsByProjectId',
      'getLoadingMaterialsByProjectId',
      'getErrorMaterialsByProjectId',
      'getRemovingMaterial',
      'getErrorRemovingMaterial'
    ])
  },
  methods: {
    ...mapActions('documents', ['fetchMaterialsByProjectId', 'removeMaterial']),
    remove(id) {
      this.contentDelete.id = id
      this.isDelete = true
    },
    async removed(id) {
      await this.removeMaterial({ documentId: id })

      if (this.getErrorRemovingMaterial.length) return

      this.isDelete = false
      const { projectId } = this.$route.params
      await this.fetchMaterialsByProjectId({ projectId })
    },
    closeDeleteModal() {
      this.isDelete = false
    },
    showNotify(message) {
      const { color, icon, timeout, position } = this.$q.config.alert
      this.$q.notify({ color, icon, message, position, timeout })
    }
  },
  watch: {
    getErrorMaterialsByProjectId: function(message) {
      if (message && message.length) this.showNotify(message)
    }
  }
}
</script>
